<template>
  <div class="column is-3 relative">
    <div
      class="card sticky is--top75 mt-12 p-2"
      style="border-radius: 15px"
    >
      <div class="card-content">
        <div class="title is-4 mb-3 has-text-primary">{{ vacancy ? dataTranslation.saveButton.update : dataTranslation.saveButton.create }}</div>

        <!-- Mexico monday form -->
        <div v-if="isMexico">
          <div class="subtitle is-6 has-text-justified">
            {{dataTranslation.descMonday}}
          </div>

          <div>
            <b-field :label="dataTranslation.idMonday">
              <b-input
                icon="barcode"
                placeholder="XXXXXXXXXX"
                maxlength="10"
                has-counter
                v-model="form.projectMondayId"
                :disabled="form.projectId?.length > 0"
              />
            </b-field>
          </div>

          <!-- If there are available projects -->
          <div v-if="availableProjects.length > 0">
            <div class="is-flex is-justify-content-space-between is-align-items-flex-end mb-5">
              <b-field
                style="width: 80%; margin-bottom: 0"
                :label="dataTranslation.projects.title"
              >
                <b-select
                  v-model="form.projectId"
                  expanded
                  icon="list-check"
                  :disabled="form.projectMondayId?.length > 0"
                >
                  <option
                    v-for="(project, index) in availableProjects"
                    :key="`project-${index}`"
                    :value="project.projectId"
                  >
                    {{ project?.name }}
                  </option>
                </b-select>
              </b-field>

              <b-button
                @click="form.projectId = ''"
                style="width: 15%"
                type="is-danger"
                icon-left="trash"
                :disabled="form.projectMondayId?.length > 0"
              ></b-button>
            </div>
          </div>

          <!-- No available projects -->
          <div v-else>
            <div class="subtitle is-6 mb-4">
              <f-icon icon="fa-regular fa-info-circle" class="has-text-danger mr-2"/>
              {{ dataTranslation.projects.disclaimer }}
            </div>
          </div>
        </div>

        <!-- Spain instructions -->
        <div class="card-content"
          v-else
        >
          <div class="subtitle is-6">{{dataTranslation.disclaimer}}</div>
        </div>

        <!-- Save vacancy -->
        <div class="mt-5">
          <b-button
            expanded
            type="is-primary"
            icon-left="save"
            @click="saveVacancy"
          >
            {{ vacancy ? dataTranslation.saveButton.update : dataTranslation.saveButton.create }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    createVacancy,
    notifyRecruiter,
    getAvailableProjects,
  } from "../../api/vacancy";
  import { mapGetters } from "vuex";
  import { externalRoles } from "@/lib/permissions";

  export default {
    components: {
    },
    props: {
      vacancy: {
        type: Object,
        required: false,
      },
      validateForms: {
        type: Function,
        required: true,
      },
      editData: {
        type: Object,
        required: false,
        default : () => {}
      },
      companyId: {
        type: String,
      }
    },
    data() {
      return {
        availableProjects: [],
        form: {
          mondayVacancyId: null,
          projectMondayId: null,
          projectId: null,
        },
      };
    },
  watch: {
    'form.projectId': function (val) { 
      if (val) {
        this.form.projectMondayId = this.availableProjects.find((project) => project.projectId === val)?.projectMondayId || this.form.projectMondayId;
      } 
    },
    editData () {
        // Solo agregamos los valores definidos en lel formulario de este componente
      // con los valores que vienen de la BD
        const dataFromDB = Object.keys(this.form).reduce((acc, key) => {
          acc[key] = this.editData[key] !== undefined ? this.editData[key] : this.form[key];
          return acc;
        }, {});       

        return this.form = dataFromDB
    },
    async companyId () { 
      this.availableProjects = await getAvailableProjects(this.companyId);
    }
  },
    emits: ['handle-update'],
    computed: {
      ...mapGetters(["projectId"]),
      user() {
        return this.$store.state.session.user;
      },
      company() {
        return this.$store.state.company;
      },
      isMexico() {
        return process.env.VUE_APP_LOCATION === "mx";
      },
      dataTranslation () {
        return this.$t("screens.vacancies.edit.saveData")
      }
    },
    methods: {
      buildPayloadForm(form) {
        form.location.state = this.stateSelected.name;
        form.location.city = this.citySelected.name;

        return form;
      },
      async saveVacancy(){
        // Validating forms        
        let { form } = await this.validateForms();

        if(!form) {
          this.$buefy.toast.open({
            message: this.dataTranslation.saveButton.error,
            type: "is-danger",
          });
          return;
        }
        
        // Setting project monday Id and project Id
        form.projectId = form.projectId ? form.projectId : this.form.projectId;

        if (form.projectId) {
          form.projectMondayId = this.availableProjects.find((project) => project.projectId === form.projectId)?.projectMondayId;
        }
        else {      
          form.projectMondayId = this.form.projectMondayId;
        }        

        // Setting recruiter
        if (!this.vacancy) {
          if (!form.recruiter && this.$isRole() === "recruiter" ) form.recruiter = this.user.id;
          else form.recruiter = this.company.headRecruiter?.id;
        }

        if (this.vacancy) {
          await this.updateVacancy(form);
        } else {
          await this.createVacancy(form);
        }
      },
      async createVacancy(form) {
        this.isLoading = true;

        try {
          const { data } = await createVacancy(this.companyId, form);

          // Notify vacancy creation to recruiter
          if (this.$hasPermissionsByRole(externalRoles)) 
            await notifyRecruiter(this.companyId, data);

          this.$router.push({ name: "Vacancies", params: { id: this.companyId } });
        } finally {
          this.isLoading = false;
        }
      },
      async updateVacancy ( form ) {
                
        if (form) {

          this.isLoading = true;

          try {
            
            await this.$api.put(`/vacancies/${this.$route.params.id}`, form)

            this.$buefy.toast.open({
              message: 'Los cambios se guardaron correctamente',
              type: 'is-success'
            })

            /* Emitir un evento para dar a conocer que ya se esta tratando de acuazalir */
            this.$emit('handle-update')

          } catch (error) {
            this.$buefy.toast.open({
              message: !error.response ? error : error.response.data.message,
              type: 'is-danger'
            })
          } finally { 
            this.isLoading = false
          }
        }else{
          this.$buefy.toast.open({
            message: 'Uno o más campos están incompletos.',
            type: 'is-danger'
          })
        }
      },
    },
  };
</script>

<style >
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

  .academicOptions--option {
    margin-top: 2px;
  }
  .language-table {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
  }
  /* Removes padding from labels */
  .field-label {
    flex-grow: 0 !important;
  }
</style>
