<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            {{ descriptionDataTranslation.title }}
            <f-icon icon="fa-regular fa-briefcase" class="ml-2" />
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Goal -->
        <div class="column is-6">
          <ValidationProvider
            :rules="descriptionDataTranslation.fields.goal.validation"
            :name="descriptionDataTranslation.fields.goal.name"
            v-slot="{ errors, valid, validate }"
          >
            <b-field
              :label="renameField(descriptionDataTranslation.fields.goal.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                :placeholder="newPlaceholder(descriptionDataTranslation.fields.goal.label)"
                icon="angle-right"
                v-model="form.goal"
                type="textarea"
                maxlength="256"
                @blur="validate"
              />
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Benefits -->
        <div class="column is-6">
          <div class="columns is-multiline">
            <!-- Title -->
            <div class="column is-12">
              <h1 class="title is-6">{{ renameField(descriptionDataTranslation.fields.benefit.label) }}</h1>
            </div>

            <!-- List of benefits -->
            <div class="column is-12">
              <div class="columns is-multiline">
                <div
                  class="column is-12 py-0 my-0"
                  v-for="(benefit, index) in form.benefits"
                  :key="`benefit-${index}`"
                >
                <template v-if="!($hasPermissionsByRole(['recruiter'])) || !editData?.scholarship">
                  <ValidationProvider
                    :ref="'benefitProvider' + index"
                    :rules="descriptionDataTranslation.fields.benefit.validation"
                    :name="`${index + 1}. ${form.benefits[index]}`"
                    v-slot="{ errors, valid }"
                  >
                    <div class="is-flex align-flex-start">
                      <b-field
                        style="width: 100%"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                        class="mb-0"
                      >
                        <b-input
                          :placeholder="newPlaceholder(descriptionDataTranslation.fields.benefit.label)"
                          icon="hand-holding-heart"
                          v-model="form.benefits[index]"
                          type="text"
                          maxlength="200"
                          @blur="validateBenefits(index)"
                        />
                      </b-field>
                      <b-button
                        class="ml-1"
                        type="is-danger"
                        icon-left="trash"
                        v-if="!($hasPermissionsByRole(['recruiter'])) || !editData?.scholarship"
                        @click="deleteBenefit(index)"
                      >
                      </b-button>
                    </div>
                  </ValidationProvider>
                </template>

                <template v-else>
                  <div class="is-flex align-flex-start">
                      <b-field
                        style="width: 100%"
                        class="mb-0"
                      >
                        <b-input
                          :disabled="true"
                          :placeholder="newPlaceholder(descriptionDataTranslation.fields.benefit.label)"
                          icon="hand-holding-heart"
                          :value="form.benefits[index]"
                          type="text"
                          maxlength="200"
                        />
                      </b-field>
                    </div>
                </template>

                </div>
              </div>
            </div>

            <!-- Add benefit -->
            <div class="column is-12 is-centered pt-0">
              <b-button
                v-if="!($hasPermissionsByRole(['recruiter'])) || !editData?.scholarship"
                type="is-primary"
                expanded
                icon-left="plus"
                @click="addBenefit"
              >
                {{ descriptionDataTranslation.fields.benefit.addBenefitButton.label }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- Activities -->
        <div class="column is-12">
          <div class="columns is-multiline">
            <div class="column is-12">
              <h1 class="title is-6">
                {{ renameField(descriptionDataTranslation.fields.activities.label) }}
              </h1>
            </div>

            <div class="column is-12">
              <div class="columns is-multiline">
                <div
                  class="column is-12"
                  v-for="(activity, index) in form.activities"
                  :key="`activity-${index}`"
                >
                  <ValidationProvider
                    :ref="'activityProvider' + index"
                    :rules="descriptionDataTranslation.fields.activities.validation"
                    :name="`${index + 1}. ${form.activities[index]}`"
                    v-slot="{ errors, valid }"
                    class="columns justify-content-start"
                  >
                    <div class="column is-11">
                      <b-field
                        style="height: 3rem"
                        horizontal
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <template #label> {{ index + 1 }}. </template>

                        <b-input
                          :placeholder="newPlaceholder(descriptionDataTranslation.fields.activities.label)"
                          icon="clipboard-list"
                          v-model="form.activities[index]"
                          type="text"
                          style="flex-grow: 2"
                          @blur="validateActivity(index)"
                        />
                      </b-field>
                    </div>

                    <div class="column is-1 pb-20 pl-12">
                      <b-button
                        v-if="index > 2"
                        type="is-danger"
                        icon-left="trash"
                        @click="removeActivity(index)"
                      >
                      </b-button>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <!-- Add activity -->
            <div class="column is-3">
              <b-button expanded type="is-primary" @click="addActivity">
                <f-icon icon="fa-regular fa-list-check" class="mr-2" />
                {{ descriptionDataTranslation.fields.activities.addActivityButton.label }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import {
  getDefaultVacancyFields,
} from "@/lib/vacancyDictionaries";

import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    descriptionData: {
      type: Object,
      required: false,
    },
    renameField: {
      type: Function,
      required: true,
    },
    newPlaceholder: {
      type: Function,
      required: true,
    },
    editData: {
      type: Object,
      required: false,
      default : () => {}
    }
  },
  mounted() {
    // Set default values
    if(this.descriptionData) {
      this.form = this.descriptionData;
    }
  },
  data() {
    return {
      form: {
        goal: null,
        benefits: [''],
        activities: ['','',''],
      },
    };
  },
  watch: {
    "$i18n.locale"(){
      this.revalidateFields();
    },
    editData () {
        // Solo agregamos los valores definidos en lel formulario de este componente
        // con los valores que vienen de la BD
        const dataFromDB = Object.keys(this.form).reduce((acc, key) => {
          acc[key] = this.editData[key] !== undefined ? this.editData[key] : this.form[key];
          return acc;
        }, {});      
        return this.form = dataFromDB;
    }
  },
  computed: {
    ...mapGetters(["companyId", "projectId"]),
    fieldNames() {
      return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
    },
    descriptionDataTranslation() {
      return this.$t("screens.vacancies.edit.description");
    },
  },
  methods: {
    addActivity() {
      this.form.activities.push("");
    },
    removeActivity(index) {
      this.form.activities.splice(index, 1);
    },
    addBenefit() {
      this.form.benefits.push("");
    },
    deleteBenefit(index) {
      this.form.benefits.splice(index, 1);
    },
    async validateForm(){
      const valid = await this.$refs.observer.validate()

      if(valid){
        return this.form
      }
      return false
    },
    validateBenefits(index) {
      const providerRef = this.$refs['benefitProvider' + index];
      if (providerRef && providerRef[0]) {
        providerRef[0].validate();
      }
    },
    validateActivity(index) {
      const providerRef = this.$refs['activityProvider' + index];
      if (providerRef && providerRef[0]) {
        providerRef[0].validate();
      }
    },
    revalidateFields() {
      Object.values(this.$refs.observer.refs).forEach((ref) => {
        if(ref.flags.touched){
          ref.validate();
        }
      })
      
      Object.keys(this.$refs).forEach((key) => {
        const refs = this.$refs[key];
        // Verifica que 'refs' es un array antes de intentar validarlo
        if (Array.isArray(refs)) {
          refs.forEach((ref) => {
            if (ref.flags && ref.flags.touched) {
              ref.validate();
            }
          });
        }
      });
    }
  },
};
</script>

<style></style>
