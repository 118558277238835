import httpApi from '@/axios'
import { ToastProgrammatic as Toast } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'

const createVacancy = async function (companyId, payload) {
  let vacancy
  try {
    vacancy = await httpApi.post(`/companies/${companyId}/vacancies`, payload)
    Dialog.alert({
      title: '¡Excelente!',
      message: `La Vacante ha sido creada y enviada,
        pronto nos contactaremos contigo para ver más detalles al respecto.
        Porfavor, agenda una llamada de alineación para poder revisar si todo está bien con tu vacante.`,
      confirmText: '¡Ok!'
    })
  } catch (error) {
    const message = error.response?.data?.message || error
    Toast.open({
      message,
      type: 'is-danger',
      duration: 3000
    })
  }
  return vacancy
}

const getOtherFields = async function (companyId) {
  let hasOtherFields = null
  let otherFields = []
  try {
    const { data } = await httpApi.get(`/aggregate-fields/hasOtherFields/${companyId}`);
    hasOtherFields = data.hasOtherFields;
    otherFields = data.otherFields;
  } catch (error) {
    console.log('The company does not have other fields for vacancies.');
  }
  return {
    hasOtherFields,
    otherFields
  }
}

const getRenamedFields = async function (companyId) {
  let renamedFields = []
  try {
    const { data } = await httpApi.get(`aggregate-fields/renamedFields/${companyId}`);
    renamedFields = data;
    console.log(companyId)
  } catch (error) {
    console.log('The company does not have renamed fields for vacancies.');
  }
  return renamedFields
}

const getProjectInfo = async function (companyId, projectId) {
  let project = {}
  try {
    const { data } = await httpApi.get(`companies/${companyId}/projects/detail/${projectId}`)
    project = data
  } catch (error) {
    Toast.open({
      message: 'Hubo un error al obtener el proyecto, inténtalo más tarde.',
      type: 'is-danger',
      duration: 3000
    })
  }
  return project
}

const getCompanyContacts = async function (companyId) {

  const { data: solicitants } = await httpApi.get(`/companies/${companyId}/solicitants`)
  const { data: hrManagers } = await httpApi.get(`/companies/${companyId}/hr-managers`)

  return [...solicitants, ...hrManagers]
}

const notifyRecruiter = async function (companyId, vacancy) {
  try {
    await httpApi.post(`/companies/${companyId}/vacancies/notify-recruiters`, vacancy)
    Toast.open({
      message: `¡Hemos notificado al especialista asignado a tu vacante!`,
      type: 'is-success'
    })
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: 'is-danger',
      duration: 3000
    })
  }
}

const getKnowledges = async function () {
  try {
    let knowledges = []

    let { data } = await httpApi.get(`knowledges`)
    if (data.length > 0) {
      knowledges = data.map(item => {
        return { name: item.name }
      })
    }

    return knowledges
  } catch (error) {
    Toast.open({
      message: 'Error al buscar conocimientos',
      type: 'is-danger'
    })
  }
}

const getAvailableProjects = async function (companyId) {
  try {
    const { data } = await httpApi.get(`/companies/${companyId}/projects/availables`)

    return data
  } catch (error) {
    Toast.open({
      message: !error.response ? error : error.response.data.message,
      type: 'is-danger',
      duration: 3000
    })
  }
}

export {
  createVacancy,
  getOtherFields,
  getRenamedFields,
  getProjectInfo,
  getCompanyContacts,
  notifyRecruiter,
  getKnowledges,
  getAvailableProjects
}
