<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            {{additionalFieldsDataTranslation.label}}
            <f-icon icon="fa-regular fa-network-wired" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3" v-if="otherFields.length">
        <div
          v-for="(field, index) in otherFields"
          :key="`field-${index}`"
          :class="wichType(field)" >

          <!-- Strings -->
          <div v-if="field.fieldType === 'string'">
            <b-field :label="field.name">
              <b-tooltip
                v-if="field.tooltip"
                :label="field.tooltip"
                :position="field.tooltipDirection">
                <b-input
                  :disabled="!field.isActive"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  type="textarea"
                  v-if="field.options === 'textarea'"
                  v-model="field.value"/>
                <b-input
                  :disabled="!field.isActive"
                  :icon="field.icon"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  v-else
                  v-model="field.value"/>
              </b-tooltip>

              <div v-else>
                <b-input
                  :disabled="!field.isActive"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  type="textarea"
                  v-if="field.options === 'textarea'"
                  v-model="field.value"/>
                <b-input
                  :disabled="!field.isActive"
                  :icon="field.icon"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  v-else
                  v-model="field.value"/>
              </div>
            </b-field>
          </div>

          <!-- Number -->
          <div v-if="field.fieldType === 'number'"  >
            <b-field :label="field.name">
              <b-tooltip
                v-if="field.tooltip"
                :label="field.tooltip"
                :position="field.tooltipDirection">
                <b-input
                  :disabled="!field.isActive"
                  :icon="field.icon"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  type="number"
                  v-model="field.value"/>
              </b-tooltip>

              <div v-else>
                <b-input
                  :disabled="!field.isActive"
                  :icon="field.icon"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  type="number"
                  v-model="field.value"/>
              </div>
            </b-field>
          </div>

          <!-- Dates -->
          <div v-if="field.fieldType === 'Date'"  >
            <b-field :label="field.name">
              <b-tooltip
                v-if="field.tooltip"
                :label="field.tooltip"
                :position="field.tooltipDirection">
                <b-datepicker
                  :disabled="!field.isActive"
                  :icon="field.icon"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  :type="field.options === 'month' ? 'month' : null"
                  :unselectable-days-of-week="[0,6]"
                  range
                  v-if="field.options === 'range'"
                  v-model="field.rangeDates"/>
                <b-datepicker
                  :disabled="!field.isActive"
                  :icon="field.icon" :unselectable-days-of-week="[0,6]"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  :type="field.options === 'month' ? 'month' : null"
                  v-else
                  v-model="field.value"/>
              </b-tooltip>

              <div v-else>
                <b-datepicker
                  :disabled="!field.isActive"
                  :icon="field.icon"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  :type="field.options === 'month' ? 'month' : null"
                  :unselectable-days-of-week="[0,6]"
                  range
                  v-if="field.options === 'range'"
                  v-model="field.rangeDates"/>
                <b-datepicker
                  :disabled="!field.isActive"
                  :icon="field.icon"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"
                  :type="field.options === 'month' ? 'month' : null"
                  :unselectable-days-of-week="[0,6]"
                  v-else
                  v-model="field.value"/>
              </div>
            </b-field>
          </div>

          <!-- Boolean -->
          <div v-if="field.fieldType === 'boolean'"  >
            <b-field :label="field.name">
              <b-tooltip
                v-if="field.tooltip"
                :label="field.tooltip"
                :position="field.tooltipDirection">
                <b-switch
                  v-model="field.value"
                  :disabled="!field.isActive"
                  :placeholder="field.placeholder"
                  :required="field.isRequired"></b-switch>
                <b-icon :icon="field.icon" :type="field.value ? 'is-primary' : ''"></b-icon>
              </b-tooltip>

              <div v-else>
                <b-switch
                  v-model="field.value"
                  :disabled="!field.isActive"
                  :placeholder="field.placeholder"
                  :required="field.isRequired">
                </b-switch>
                <b-icon
                  :icon="field.icon"
                  :type="field.value ? 'is-primary' : ''"></b-icon>
              </div>
            </b-field>
          </div>

          <!-- tags Array -->
          <div
            v-if="field.fieldType === 'Array'"
            style="width: fit-content;">
            <b-field :label="field.name">
              <b-icon :icon="field.icon"></b-icon>
              <b-tooltip
                v-if="field.tooltip"
                :label="field.tooltip"
                :position="field.tooltipDirection">
                <div style="display: grid; grid-template-columns: repeat(2, 1fr)">
                  <b-checkbox
                    class="mb-2"
                    v-model="field.value"
                    v-for="(option,index) in field.options"
                    :key="`option-${index}`"
                    :native-value="option"
                    :disabled="!field.isActive"
                    :required="field.isRequired">
                      {{ option }}
                  </b-checkbox>
                </div>
              </b-tooltip>

              <div v-else>
                <div style="display: grid; grid-template-columns: repeat(2, 1fr)">
                  <b-checkbox
                    class="mb-2"
                    v-model="field.value"
                    v-for="(option,index) in field.options"
                    :key="`option-${index}`"
                    :native-value="option"
                    :disabled="!field.isActive"
                    :required="field.isRequired">
                      {{ option }}
                  </b-checkbox>
                </div>
              </div>
            </b-field>
          </div>

          <!-- select Array -->
          <div v-if="field.fieldType === 'Select'">
            <b-field :label="field.name">
              <b-tooltip
                v-if="field.tooltip"
                :label="field.tooltip"
                :position="field.tooltipDirection">
                <b-select
                  v-model="field.value"
                  :disabled="!field.isActive"
                  :placeholder="field.placeholder"
                  :icon="field.icon"
                  expanded
                  :required="field.isRequired">
                  <option
                    v-for="(option, index) in field.options"
                    :value="option"
                    :key="`option-${index}`">
                    {{ option }}
                  </option>
                </b-select>
              </b-tooltip>

              <div v-else>
                <b-select
                  v-model="field.value"
                  :disabled="!field.isActive"
                  :placeholder="field.placeholder"
                  :icon="field.icon"
                  expanded
                  :required="field.isRequired">
                  <option
                    v-for="(option, index) in field.options"
                    :value="option"
                    :key="`option-${index}`">
                    {{ option }}
                  </option>
                </b-select>
              </div>
            </b-field>
          </div>
        </div>
      </div>

      <!-- No Fields -->
      <div class="columns is-multiline px-3" v-else>
        <div class="column">
          <p class="subtitle is-6">
            {{additionalFieldsDataTranslation.info}}
          </p>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import {
    getDefaultVacancyFields,
  } from "@/lib/vacancyDictionaries";

  import {
    getOtherFields,
  } from "../../api/vacancy";

  import { mapGetters } from "vuex";

  export default {
    props:{
      editData: {
        type: Object,
        required: false,
        default : () => {}
      }
    },
    components: {
    },
    async mounted() {
      if(this.editData){
        this.setOtherFields(this.editData.otherFields)
        return
      }
    },
    data() {
      return {
        renamedFields: [],
        otherFields: [],
      };
    },
    watch: {
      async companyId(){
        if(!this.companyId) return;

        const { otherFields } = await getOtherFields(this.companyId);
        this.setOtherFields(otherFields)
      },
      async editData () {
        if(this.editData){
          this.setOtherFields(this.editData.otherFields)
          return
        }

        if(!this.companyId) return;
        const { otherFields } = await getOtherFields(this.companyId);
        this.setOtherFields(otherFields)
      }
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      fieldNames() {
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
      additionalFieldsDataTranslation() {
        return this.$t("screens.vacancies.edit.additionalFields");
      },
    },
    methods: {
      setOtherFields(vacancyOtherFields){
        vacancyOtherFields.forEach(field => {
          if(field.fieldType === 'Date'){
            field.value = new Date(field.value)
          }
        })
        this.otherFields = vacancyOtherFields
      },
      wichType(field){
        if(field.options === 'default' ) return 'column is-4'
        if(field.options === 'textarea' ) return 'column is-6'
        if(field.fieldType === 'number' ) return 'column is-4'
        if(field.fieldType === 'Date' ) return 'column is-4'
        if(field.fieldType === 'boolean' ) return 'column is-2'
        if(field.fieldType === 'Array' ) {
          return field.options.length > 4 ? 'column is-6' : 'column is-4'
        }
        if(field.fieldType === 'Select' ) return 'column is-4'
      },
      renameField(actualName) {
        const newField = this.renamedFields.find((field) => actualName === field.actualName);
        return newField?.newName || actualName;
      },
      newPlaceholder(actualName) {
        const newField = this.renamedFields.find((field) => actualName === field.actualName);
        return newField?.newPlaceholder || "";
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          return  {otherFields: this.otherFields}
        }
        return false
      },
    },
  };
</script>

<style >

</style>
