<template>
  <b-menu>

    <b-menu-list>

      <b-menu-item
        v-for="(item, index) in items"
        :key="`item-${index}`"
        tag="router-link"
        :icon="item.icon"
        :label="item.label"
        :to="item.href"
        :active="routeName === item.name"
        :disabled="item.disabled"
        />
    </b-menu-list >
  </b-menu>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ... mapGetters(['companyId']),
    user () {
      return this.$store.state.session.user
    },
    routeName () {
      return this.$route.name
    },
    items () {
      const menuItems = [
        {
          name: 'CompanyDetail',
          icon: 'buildings',
          label: 'Empresa',
          href: `/empresa/${this.companyId}`,
          roles: ['superqueen', 'manager', 'leader-recruiter-manager', 'recruiter-manager', 'recruiter']
        },
        {
          name: 'LandingPage',
          icon: 'panorama',
          label: 'Página',
          href: `/empresa/${this.companyId}/landing`,
          roles: ['superqueen', 'manager']
        },
        {
          name: 'Projects',
          icon: 'list-check',
          label: 'Proyectos',
          href: `/empresa/${this.companyId}/proyectos`,
          roles: ['superqueen', 'manager', 'leader-recruiter-manager', 'recruiter-manager', 'recruiter'],
          flag: 'true',
        },
        {
          name: 'Vacancies',
          icon: 'folder-magnifying-glass',
          label: 'Vacantes',
          href: `/empresa/${this.companyId}/vacantes`,
          roles: ['superqueen', 'manager', 'leader-recruiter-manager', 'recruiter-manager', 'recruiter', 'solicitant', 'hrmanager']
        },
        {
          name: 'CompanyUsersContact',
          icon: 'address-book',
          label: 'Contactos',
          href: `/empresa/${this.companyId}/usuarios-de-empresa`,
          roles: ['superqueen', 'manager', 'leader-recruiter-manager', 'recruiter-manager', 'recruiter']
        },
        {
          name: 'AccountHome',
          icon: 'users',
          label: 'Candidatos contratados',
          href: `/empresa/${this.companyId}/cuentas`,
          roles: ['superqueen', 'manager', 'account', 'account-manager']
        },
        {
          name: 'CompanyUsers',
          icon: 'address-card',
          label: 'Usuarios',
          href: `/empresa/${this.companyId}/usuarios`,
          roles: ['superqueen', 'manager', 'leader-recruiter-manager', 'recruiter-manager',  'account-manager']
        },
        {
          name: 'CompanySettings',
          icon: 'cogs',
          label: 'Configuración',
          href: `/empresa/${this.companyId}/configuracion`,
          roles: ['superqueen', 'manager', 'leader-recruiter-manager', 'recruiter-manager']
        }
      ]
      return menuItems.filter(item => item.roles.includes(this.$isRole()))
    }
  }
}
</script>
