<template>
  <div class="container">
    <div class="mb-10 pt-15 is-flex justify-space-between">
      <div>
        <h1 class="title is-primary">
          {{ vacancy.name }}
        </h1>
      </div>

      <div v-if="['superqueen', 'recruiter-manager', 'recruiter'].includes($isRole())"
        class="is-flex justify-center">

        <b-button
          rounded
          icon-left="arrow-left"
          type="is-primary"
          tag="router-link"
          :to="getReturningPageParams">
          Regresar
        </b-button>
      </div>

      <div v-if="['hrmanager','solicitant'].includes($isRole())">
        <b-button
          rounded
          icon-left="arrow-left"
          type="is-primary"
          tag="router-link"
          :to="getReturningPageParams">
          Regresar
        </b-button>
      </div>
    </div>
    <div v-if="$hasPermissionsByRole(downloadCandidatesAllowedRoles)"
      class="column is-12">
      <div class="is-flex justify-flex-end">
        <b-button
          rounded
          type="is-primary"
          :href="downloadCandidates"
          target="_blank"
          tag="a">
          Descargar candidatos
        </b-button>
      </div>
    </div>
    <!-- Tabs Here -->
    <b-tabs
      v-model="activeTab"
      type="is-boxed"
      :animated="false">
      <b-tab-item
        v-for="(tab, index) in tabs"
        :key="`tab-${index}`"
        :value="`${index}`"
        :label="tab.label">
        <component v-if="activeTab === index" :is="tab.component"/>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VacancyCandidatesApplying from '@/screens/VacancyCandidatesApplying'
import VacancyCandidatesSelected from '@/screens/VacancyCandidatesSelected'
import VacancyCandidatesSent from '@/screens/VacancyCandidatesSent'
import VacancyCandidatesRejected from '@/screens/VacancyCandidatesRejected'
import VacancyCandidatesPrehired from '@/screens/VacancyCandidatesPrehired'
import VacancyCandidatesHired from '@/screens/VacancyCandidatesHired'
import { manageAllCandidatesAllowedRoles, downloadCandidatesAllowedRoles } from '../lib/permissions'

export default {
  components: {
    VacancyCandidatesApplying,
    VacancyCandidatesSelected,
    VacancyCandidatesSent,
    VacancyCandidatesRejected,
    VacancyCandidatesPrehired,
    VacancyCandidatesHired
  },
  data () {
    return {
      activeTab: 0,
      vacancy: {
        name: null,
        id: null
      },
      downloadCandidatesAllowedRoles,
      authRoles: {
				assigned: ['recruiter', 'solicitant', 'hrmanager', 'recruiter-manager']
			}
    }
  },
  computed: {
    ...mapGetters(['companyId']),
    token () {
      return this.$store.state.session.token
    },
    id () {
      return this.$route.params.id
    },
    queryStatus () {
      return this.$route.query.s
    },
    tabs () {
      const tabs = [
        { label: 'En aplicación', status: 'applying', component: 'VacancyCandidatesApplying', roles: manageAllCandidatesAllowedRoles },
        { label: 'Seleccionad@', status: 'selected', component: 'VacancyCandidatesSelected', roles: manageAllCandidatesAllowedRoles },
        { label: 'En revisión con la empresa', status: 'sent', component: 'VacancyCandidatesSent', roles: ['superqueen', 'manager', 'recruiter-manager', 'recruiter', 'hrmanager', 'solicitant'] },
        { label: 'Seleccionad@ por la empresa', status: 'pre-hired', component: 'VacancyCandidatesPrehired', roles: ['superqueen', 'manager', 'recruiter-manager', 'recruiter', 'hrmanager', 'solicitant'] },
        { label: 'Contratad@', status: 'hired', component: 'VacancyCandidatesHired', roles: ['superqueen', 'manager', 'recruiter-manager', 'recruiter', 'hrmanager', 'solicitant'] },
        { label: 'Rechazad@', status: 'rejected', component: 'VacancyCandidatesRejected', roles: manageAllCandidatesAllowedRoles }
      ]
      return tabs.filter(tab => tab.roles.includes(this.$isRole()))
    },
    downloadCandidates () {
      return `${process.env.VUE_APP_API_URL}/v1/vacancies/${this.id}/applications/export?token=${this.token}`
    },
    getReturningPageParams(){
      if (this.$route.query.from == 'projects'){
        return { name: 'Projects', params: { id: this.vacancy.company?.id  }, query: { project: this.vacancy.projectId}}
      }
      else {
        return { name: 'Vacancies', params: { id: this.vacancy.company?.id } }
      }
    },
  },
  async mounted () {    
    const index = this.tabs.findIndex(tab => tab.status === this.queryStatus)
    if (index >= 0) {
      this.activeTab = index
    }
    await this.getVacancy()

    const userRol = this.$store.state.session.user.role
    if(this.authRoles.assigned.includes(userRol)){
      const userCompaniesId = this.$store.state.userCompanies
      if (!userCompaniesId?.includes(this.vacancy.company?.id)) {
          this.$router.push("/")
      }
    }
  },
  methods: {
    async getVacancy () {
      const { data } = await this.$api.get(`/vacancies/${this.id}`)
      this.vacancy = data
    }
  }
}
</script>
