import { ToastProgrammatic as Toast } from 'buefy'

// Cabeceras mágicas de los archivos
const fileSignatures = {
    jpg: [0xFF, 0xD8, 0xFF],
    jpeg: [0xFF, 0xD8, 0xFF],
    png: [0x89, 0x50, 0x4E, 0x47],
    gif: [0x47, 0x49, 0x46, 0x38],
    webp: [0x52, 0x49, 0x46, 0x46],
    tiff: [0x49, 0x49, 0x2A, 0x00],
    ico: [0x00, 0x00, 0x01, 0x00],
    pdf: [0x25, 0x50, 0x44, 0x46], // %PDF
    docx: [0x50, 0x4B, 0x03, 0x04], // ZIP (OpenXML: DOCX)
    pptx: [0x50, 0x4B, 0x03, 0x04], // ZIP (OpenXML: PPTX)
};

/**
 * Aplica una función hash a la cabecera mágica de un archivo (suma de bytes).
 * 
 * @param {Array<number>} fileExtension - Archivo a validar.
 * @returns {number} - Valor hash de la cabecera mágica.
 */
function hashSignature(fileExtension) {
    const signatures = fileSignatures[fileExtension];

    return signatures.reduce((hash, byte) => {
        return hash + byte;
    }, 0);
}


/**
 * Valida si un archivo tiene una extensión y cabecera mágicas válidas.
 * 
 * @param {File} file - Archivo a validar.
 * @param {Array<string>} allowedExtensions - Lista de extensiones permitidas (ejemplo: ['jpg', 'png', 'pdf']).
 * @param {string} fieldName - Nombre del campo del archivo (para mensajes de error).
 * @returns {Promise<boolean>} - `true` si el archivo es válido, `false` en caso contrario.
 */
export async function validateFileExtension(file, allowedExtensions = [], fieldName = "archivo") {

    if (!file) {
        return false;
    }

    const fileExtension = file.name.split('.').pop().toLowerCase(); // Obtener la extensión del archivo

    const isValid = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const content = new Uint8Array(event.target.result);

            // Validar el contenido real del archivo con las cabeceras mágicas
            const detectedExtension = Object.keys(fileSignatures).find((ext) =>
                fileSignatures[ext].every((byte, index) => content[index] === byte)
            );

            const isExtensionValid = allowedExtensions.includes(fileExtension); // Extensión del archivo válida
            const isHeaderValid = allowedExtensions.includes(detectedExtension); // Contenido válido según la cabecera

            // Validación específica para webp
            if (detectedExtension === "webp") {
                const webpHeader = new TextDecoder().decode(content.subarray(8, 12));
                if (webpHeader !== "WEBP") {
                    resolve(false);
                    return;
                }
            }

            // Validación para archivos OpenXML (DOCX, PPTX)
            if (["docx", "pptx"].includes(detectedExtension)) {
                const fileType = fileExtension === detectedExtension ? detectedExtension : null;
                if (!fileType) {
                    resolve(false);
                    return;
                }
            }
            // Validación final: cabecera, extensión, y consistencia
            const isConsistent = hashSignature(detectedExtension) === hashSignature(fileExtension);
            resolve(isHeaderValid && isExtensionValid && isConsistent);
        };

        reader.onerror = function () {
            resolve(false);
        };

        reader.readAsArrayBuffer(file);
    });

    if (!isValid) {
        Toast.open({
            message: `El ${fieldName} no tiene un formato válido, solo se permiten ${allowedExtensions.join(", ")}.`,
            type: "is-danger",
        });
    }

    return isValid;
}