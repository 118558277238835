import VacancyDetail from '@/screens/VacancyDetail'
import VacancyEdit from '@/screens/VacancyEdit'
import VacancyCreate from '@/screens/VacancyCreate'
import VacancyCandidates from '@/screens/VacancyCandidates'
import CandidateDetail from '@/screens/CandidateDetail'
import i18n from "../plugins/i18n"
import content from '../content';

const changeLanguage = (to, from, next) => {
  const VALID_LANGUAGES = ['es', 'en', 'mx']
  let lang = content.getLocales(to.params.lang || process.env.VUE_APP_LOCATION)  // Si no hay 'lang', usa 'es' por defecto
  if (!VALID_LANGUAGES.includes(lang)) lang = 'mx'
  if (i18n.locale !== lang) {
    i18n.locale = lang
  }
  next()
}
//Parametro lang = Lenguaje (mx|es|en)
const recruitment = [
  {
    path: '/vacante',
    component: {
      render: r => r('router-view')
    },
    name: 'Vacancy',
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager', 'account-manager', 'account']
    },
    redirect: { name: 'VacancyDetail' },
    children: [
      {
        path: 'nueva/:lang(mx|en|es)?',
        component: VacancyCreate,
        name: 'VacancyCreate',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'manager']
        },
        beforeEnter: (to, from, next) => changeLanguage(to, from, next)
      },
      {
        path: ':id',
        component: VacancyDetail,
        name: 'VacancyDetail',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
        }
      },
      {
        path: ':id/edit/:lang(mx|en|es)?',
        component: VacancyEdit,
        name: 'VacancyEdit',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'manager']
        },
        beforeEnter: (to, from, next) => changeLanguage(to, from, next)
      },
      {
        path: ':id/candidatos',
        name: 'VacancyCandidates',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager', 'account-manager', 'account'],
        },
        component: VacancyCandidates,
      }
    ]
  },
  {
    path: '/candidato',
    name: 'Candidate',
    component: {
      render: r => r('router-view')
    },
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
    },
    children: [
      {
        path: ':id/:lang(mx|en|es)?',
        name: 'CandidateDetail',
        component: CandidateDetail,
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
        },
        beforeEnter: (to, from, next) => changeLanguage(to, from, next)
      },
    ]
  }
]

export default recruitment