import { render, staticRenderFns } from "./CustomEvaluationForm.vue?vue&type=template&id=f5a932ba&scoped=true"
import script from "./CustomEvaluationForm.vue?vue&type=script&lang=js"
export * from "./CustomEvaluationForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5a932ba",
  null
  
)

export default component.exports