<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            {{ generalDataTranslation.title }}
            <f-icon icon="fa-regular fa-house" class="ml-2" />
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Name -->
        <div class="column is-6">
          <ValidationProvider
            :rules="generalDataTranslation.fields.name.validation"
            :name="generalDataTranslation.fields.name.name"
            v-slot="{ errors, valid, validate }"
          >
            <b-field
              :label="renameField(generalDataTranslation.fields.name.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                icon="angle-right"
                :placeholder="newPlaceholder(generalDataTranslation.fields.name.label)"
                v-model="form.name"
                maxlength="64"
                @blur="validate"
              />
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Duration -->
        <div class="column is-3">
          <ValidationProvider
            :rules="generalDataTranslation.fields.duration.validation"
            :name="generalDataTranslation.fields.duration.name"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(generalDataTranslation.fields.duration.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                icon="calendar-days"
                :placeholder="newPlaceholder(generalDataTranslation.fields.duration.label)"
                v-model="form.duration"
                expanded
              >
                <option
                  v-for="(month , index) in durationOptions"
                  :key="index"
                  :value="month"
                >
                  {{ month }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Positions -->
        <div class="column is-3">
          <ValidationProvider
            :rules="generalDataTranslation.fields.places.validation"
            :name="generalDataTranslation.fields.places.name"
            v-slot="{ errors, valid,validate }"
          >
            <b-field
              :label="renameField(generalDataTranslation.fields.places.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                :placeholder="newPlaceholder(generalDataTranslation.fields.duration.label)"
                icon="user-plus"
                type="number"
                v-model="form.places"
                @blur="validate"
              />
            </b-field>
          </ValidationProvider>
        </div>

        <!-- State -->
        <div class="column is-4">
          <ValidationProvider
            :rules="generalDataTranslation.fields.state.validation"
            :name="generalDataTranslation.fields.state.name"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(generalDataTranslation.fields.state.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                icon="map-pin"
                :placeholder="newPlaceholder(generalDataTranslation.fields.state.label)"
                v-model="stateSelected"
                expanded
                v-if="isMexico"
              >
                <option
                  v-for="(state, index) in states"
                  :key="`state-${index}`"
                  :value="state"
                >
                  {{ state.name }}
                </option>
              </b-select>

              <b-select
                v-else
                icon="map-pin"
                :placeholder="newPlaceholder(generalDataTranslation.fields.state.label)"
                v-model="stateSelected"
                expanded
              >
                <option
                  v-for="(province, index) in provinces"
                  :key="`province-${index}`"
                  :value="province"
                >
                  {{ province.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- City -->
        <div class="column is-4">
          <ValidationProvider
            :rules="generalDataTranslation.fields.city.validation"
            :name="generalDataTranslation.fields.city.name"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(generalDataTranslation.fields.city.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                :placeholder="newPlaceholder(generalDataTranslation.fields.city.label)"
                icon="map-pin"
                v-model="citySelected"
                :disabled="stateCities.length === 0"
                expanded
              >
                <option
                  v-for="(city, index) in stateCities"
                  :key="`city-${index}`"
                  :value="city"
                >
                  {{ city.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Schedule -->
        <div class="column is-4">
          <ValidationProvider
            :rules="generalDataTranslation.fields.schedule.validation"
            :name="generalDataTranslation.fields.schedule.name"
            v-slot="{ errors, valid,validate }"
          >
            <b-tooltip
              style="width: 100%"
              :label="generalDataTranslation.fields.schedule.tooltip"
              position="is-bottom"
              animated
            >
              <b-field
                :label="renameField(generalDataTranslation.fields.schedule.label)"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-input
                  icon="clock"
                  :placeholder="newPlaceholder(generalDataTranslation.fields.schedule.label)"
                  v-model="form.schedule"
                  @blur="validate"
                />
              </b-field>
            </b-tooltip>
          </ValidationProvider>
        </div>

        <!-- Show Scholarship-->
      <template v-if="!($hasPermissionsByRole(['recruiter'])) || !editData?.scholarship">
        <div class="column mb-5">
          <b-field :label="renameField(generalDataTranslation.fields.showScholarship.label)">
            <div class="is-flex is-flex-direction-column is-align-items-center pt-2">
              <b-switch
                :rounded="false"
                v-model="form.showScholarship"
              />
            </div>
          </b-field>
        </div>

        <!-- Scholarship -->
        <div class="column is-4">
          <ValidationProvider
            :rules="generalDataTranslation.fields.scholarship.validation"
            :name="generalDataTranslation.fields.scholarship.name"
            v-slot="{ errors, valid,validate }"
          >
            <b-field
              :label="renameField(generalDataTranslation.fields.scholarship.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                :placeholder="newPlaceholder(generalDataTranslation.fields.scholarship.label)"
                icon="money-bill"
                v-model="form.scholarship"
                v-if="isMexico"
                @blur="validate"
              />

              <b-input
                v-else
                :placeholder="newPlaceholder(generalDataTranslation.fields.scholarship.label)"
                icon="euro-sign"
                v-model="form.scholarship"
                @blur="validate"
              />
            </b-field>
          </ValidationProvider>
        </div>
      </template>

      <template v-else>
        <div class="column is-4">
          <b-field
              :label="renameField(generalDataTranslation.fields.scholarship.label)"
            >
              <b-input
                :placeholder="newPlaceholder(generalDataTranslation.fields.scholarship.label)"
                icon="money-bill"
                :value="form.scholarship"
                v-if="isMexico"
                :disabled="true"
              />

              <b-input
                v-else
                :disabled="true"
                :placeholder="newPlaceholder(generalDataTranslation.fields.scholarship.label)"
                icon="euro-sign"
                :value="form.scholarship"
              />
            </b-field>
        </div>
      </template>

        <!-- Modality -->
        <div class="column is-5">
          <ValidationProvider
            :rules="generalDataTranslation.fields.modality.validation"
            :name="generalDataTranslation.fields.modality.name"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(generalDataTranslation.fields.modality.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                icon="square-user"
                :placeholder="newPlaceholder(generalDataTranslation.fields.modality.label)"
                v-model="form.modality"
                expanded
              >
                <option
                  v-for="(value,key) in modalities"
                  :key="key"
                  :value="value"
                >
                  {{ value }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import provinces from "@/lib/ES/provinces";
import states from "@/lib/states";
import { getStateCities } from "@/lib/vacancyDictionaries";

import { mapGetters } from "vuex";
import { availableModalities, durations } from "../../lib/vacancyDictionaryout";

export default {
  components: {},
  props: {
    generalData: {
      type: Object,
      required: false,
    },
    renameField: {
      type: Function,
      required: true,
    },
    newPlaceholder: {
      type: Function,
      required: true,
    },
    editData: {
      type: Object,
      required: false,
      default : () => {}
    }
  },
  mounted () {    
    // Set default values    
    if (this.generalData) {
      this.form = this.generalData;

      // Asigna stateSelected y citySelected al montar
      if (this.form.location.state) {
        if (this.isMexico) {
          this.stateSelected = this.states.find(
            (state) => state.name === this.form.location.state
          );
        } else {
          this.stateSelected = this.provinces.find(
            (province) => province.name === this.form.location.state
          );
        }
      }

      if (this.form.location.city) {
        this.$nextTick(() => {
          this.citySelected = this.stateCities.find(
            (city) => city.name === this.form.location.city
          );
        });
      }
    }

    if (this.$route.path.includes("en")) this.$router.push("/vacante/nueva");
  },
  data() {
    return {
      citySelected: null,
      provinces,
      states,
      stateSelected: null,
      form: {
        name: null,
        duration: null,
        places: 1,
        location: {
          state: null,
          city: null,
        },
        schedule: null,
        showScholarship: false,
        scholarship: null,
        modality: null,
      },
    };
  },
  watch: {
    stateSelected () {      
      if (this.stateSelected?.id == "00" && this.isSpain) {
        this.citySelected = this.provincesMunicipalities[0];
      }
    },
    "$i18n.locale"(){
      this.revalidateFields();
    },
    editData() {
    // Verifica que editData no sea undefined o null
    if (this.editData) {
      // Copia las propiedades de nivel superior de editData a form
      Object.keys(this.form).forEach((key) => {
        if (this.editData[key] !== undefined) {
          if (key === 'location') {
            if (this.editData.location) {
              if (this.isMexico) {
                this.stateSelected = this.states.find(
                  (state) => state.name === this.editData.location.state
                );
              } else {
                this.stateSelected = this.provinces.find(
                  (province) => province.name === this.form.location.state
                );
              }
              this.$nextTick(() => {
                this.citySelected = this.stateCities.find(
                  (city) => city.name === this.editData.location.city
                );
              });
            }
          } else {
            this.form[key] = this.editData[key];
          }
        }
      });
    }
  },
  },
  computed: {
    ...mapGetters(["companyId", "projectId"]),
    stateCities() {
      return getStateCities(
        this.stateSelected?.id,
        process.env.VUE_APP_LOCATION
      );
    },
    isMexico() {
      return process.env.VUE_APP_LOCATION === "mx";
    },
    isSpain() {
      return process.env.VUE_APP_LOCATION === "es";
    },
    generalDataTranslation() {
      return this.$t("screens.vacancies.edit.generalData");
    },
    durationOptions(){
      return durations[this.$i18n.locale]
    },
    modalities () {
      return availableModalities[this.$i18n.locale]
    },
  },
  methods: {
    async validateForm () {
      //Si no selecciona un estado y ciudad valido no hacer nada
      if (!this.stateSelected || !this.citySelected) {
        return
      }      
      this.form.location.state = this.stateSelected.name
      this.form.location.city = this.citySelected.name
      
      const valid = await this.$refs.observer.validate()
      if(valid){
        return this.form
      }
      return false
    },
    revalidateFields() { 
      Object.values(this.$refs.observer.refs).forEach((ref) => {
        if(ref.flags.touched){
          ref.validate();
        }
      })
    }
  },
};
</script>

<style scoped>
@import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

.academicOptions--option {
  margin-top: 2px;
}
.language-table {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  row-gap: 1rem;
}
</style>
