<template>
  <div>
    <div class="columns">

      <div class="column">
        <span class="is-size-3">Usuarios</span>
      </div>

    </div>

    <div class="mb-20 is-flex justify-space-between">

      <div class="is-flex">
        <b-select
          class="mr-3"
          expanded
          icon="users"
          placeholder="Rol"
          rounded
          v-model="roleSelected">
          <option
            v-for="(role, key, index) in searchRoles"
            :key="`role-${index}`"
            :value="key">
              {{ role }}
          </option>
        </b-select>

        <b-input
          :disabled="!roleSelected"
          class="mr-3"
          expanded
          icon="at"
          placeholder="Correo electrónico"
          rounded
          v-model="userMail">
        </b-input>

        <b-select
          :disabled="!roleSelected"
          class="mr-3"
          expanded
          icon="industry"
          placeholder="Empresa"
          rounded
          v-model="company">
          <option value="">
            Todas
          </option>
          <option
            v-for="(company, index) in companies"
            :key="`company-${index}`"
            :value="company.id">
              {{ company.name }}
          </option>
        </b-select>

        <b-button
          :disabled="!roleSelected"
          @click="getUsers"
          icon-left="magnifying-glass"
          rounded
          type="is-primary"
        />
      </div>

      <b-button
        rounded
        type="is-primary"
        icon-right="plus"
        @click="openCreateDialog">
        Agregar usuario
      </b-button>
    </div>

    <div>
      <div class="card">

        <div
        class="card-content"
        v-for="(user, index) in users"
        :key="`user-${index}`">

          <template v-if="includesEmail(user)">

            <template v-if="!includesCompanies(user)">

              <hr v-if="index > 0">

              <div class="columns">

                <div class="column is-2  is-flex is-justify-content-center is-align-items-center">

                  <div v-if="user.photo" class="image" style="max-width: 70px">
                    <img class="is-rounded" :src="user.photo.url">
                  </div>

                  <div v-else>
                    <b-field class="file is-blue">
                      <b-upload
                        @input="uploadPhoto($event, user)"
                        accept=".gif, .jpeg, .jpg, .png"
                        class="file-label"
                        rounded
                        v-model="file">
                        <span class="file-cta">
                            <b-icon
                              class="file-icon"
                              icon="upload">
                            </b-icon>
                            <span class="file-label">
                              Subir foto
                            </span>
                        </span>
                      </b-upload>
                    </b-field>
                  </div>

                </div>

                <div class="column is-3">

                  <div>
                    {{ user.names }} {{ user.paternalSurname }} {{ user.maternalSurname }}
                  </div>

                  <div>
                    <a :href="`mailto:${user.email}`">
                      {{ user.email }}
                    </a>
                    <div class="is-flex is-justify-content-space-around">
                      <b-tooltip
                        label="Copiar correo"
                        position="is-bottom">
                        <a>
                          <b-icon
                            class="iconos"
                            icon="copy"
                            v-clipboard:copy="user.email"
                            v-clipboard:error="onError"
                            v-clipboard:success="onCopy">
                          </b-icon>
                        </a>
                      </b-tooltip>

                      <b-tooltip
                        label="Enviar correo"
                        position="is-bottom">
                        <a
                          :href="`https://mail.google.com/mail/u/0/?fs=1&to=${user.email}&tf=cm`"
                          target="_blank">
                          <b-icon
                            class="iconos"
                            icon="envelope-open-text"
                            >
                          </b-icon>
                        </a>
                      </b-tooltip>
                    </div>
                  </div>

                </div>

                <div class="column is-3">
                  <p class="mx-2">
                    {{ searchRoles[user.role] }}
                  </p>
                </div>

                <div class="column is-3">
                  <p>
                    Empresas:
                  </p>

                  <div v-if="user.company">
                    <b-taglist>
                      <b-tag
                        type="is-dark"
                        :style="{ background: user.company.mainColor || '#42CBB6' }">
                          {{ user.company.name }}
                      </b-tag>
                    </b-taglist>
                  </div>

                  <div v-if="user.companies.length">
                    <b-taglist>
                      <b-tag
                        v-for="company in user.companies"
                        :key="company.id"
                        type="is-dark"
                        :style="{ background: company.company.mainColor || '#42CBB6' }">
                          {{ company.company.name }}
                      </b-tag>
                    </b-taglist>
                  </div>
                </div>

                <div class="column is-1">
                  <div class="is-flex is-justify-content-end">
                    <b-dropdown
                      :triggers="['hover']"
                      aria-role="list"
                      position="is-bottom-left">
                        <button class="button" slot="trigger">
                          <b-icon icon="ellipsis-stroke"></b-icon>
                        </button>

                        <b-dropdown-item
                          aria-role="listitem"
                          @click="openEdit(user)"
                          v-if="hasEditionPermissions(user)"  
                          >
                          <div>
                            <b-icon icon="pencil" /> Editar usuario
                          </div>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="!(user.role === 'hrmanager' || user.role === 'solicitant')"
                          aria-role="listitem"
                          @click="openAssign(user)">
                          <div>
                            <b-icon icon="building" /> Asignar empresa
                          </div>
                        </b-dropdown-item>

                        <b-dropdown-item
                          aria-role="listitem"
                          @click="openRemoveDialog(user)"
                          v-if="hasEditionPermissions(user)"   
                        >
                          <div class="has-text-danger">
                            <b-icon icon="trash" /> Eliminar usuario
                          </div>
                        </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>

              </div>
            </template>

          </template>

        </div>

      </div>

      <hr>

      <div class="columns">
        <div class="column is-12">
          <b-pagination
            :current.sync="current"
            :per-page="perPage"
            :range-after="rangeAfter"
            :range-before="rangeBefore"
            :total="total"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            order="is-centered"
            rounded
            >
          </b-pagination>
        </div>
      </div>

    </div>

    <UserEdit ref="edit" @updated="getUsers"/>

    <UserCreate @updated="getUsers" ref="create"/>

    <UserAssignCompany ref="assign" @updated="getUsers"/>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import UserEdit from '../components/modals/UserEdit.vue'
import UserCreate from '../components/modals/UserCreate.vue'
import UserAssignCompany from '../components/modals/UserAssignCompany.vue'
import { searchRoles, allowedRecruitersManagersEditionRoles } from '@/lib/roles.js'
import { validateFileExtension } from '../utils/validateFileExtension.js'

export default {
  name: 'UsersList',
  components: {
    UserEdit,
    UserCreate,
    UserAssignCompany
  },
  data() {
    return {
      searchRoles,
      allowedRecruitersManagersEditionRoles,
      validateFileExtension,
      isLoading: false,
      file: null,
      users: [],
      current: 1,
      perPage: 15,
      rangeAfter: 3,
      rangeBefore: 3,
      total: 0,
      companies: [],
      kind: null,
      roleSelected: null,
      company: null,
      userMail: null
    }
  },
  watch: {
    async current () {
      await this.getUsers()
    }
  },
  methods: {
    async getUsers () {
      this.isLoading = true
      const params =  [
        'deleted=false',
        `role=${this.roleSelected}`,
        `skip=${(this.current - 1) * this.perPage}`,
        `limit=${this.perPage}`,
        `fields=
        account,
        accountManager,
        company,
        email,
        maternalSurname,
        names,
        paternalSurname,
        photo,
        recruiter,
        recruiterManager,
        role`,
        `sort=role,email`
      ].join('&')
      try {
        const { data } = await this.$api.get( `/users?${params}` )
        this.users = data.data
        this.total = data.total
      } catch (error) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    openCreateDialog () {
      this.$refs.create.open()
    },
    async uploadPhoto ( event, user ) {
      if (this.file) {
        const isFileValid = await this.validateFileExtension(this.file, ["jpg", "jpeg", "png", "gif"]);
        if (!isFileValid) return; // Detener si falla
      }

      this.isLoading = true
      try {
        if (this.file) {
          const formData = new FormData()
          formData.append( 'file', event )
          await this.$api.post(`/users/${user._id}/upload-photo`, formData,{
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          this.resetPhoto()
          await this.getUsers()
        }
      } catch ( error ) {
        const status = error.response?.status; // Código de estado HTTP
        const data = error.response?.data; // Datos de la respuesta

        if (status === 422 && data?.malicious) {
          // Archivo con malware
          this.$buefy.toast.open({
            message: "El archivo que intentas subir es potencialmente peligroso y no puede ser cargado.",
            type: "is-danger",
          });
        } else if (status === 415) {
          // Extensión de archivo no válida
          this.$buefy.toast.open({
            message: data?.validExtension,
            type: "is-danger",
          });
        } else {
          // Otros errores genéricos
          const message = data?.message || error.message || "Error desconocido.";
          this.$buefy.toast.open({
            message,
            type: "is-danger",
          });
        }
      }
      this.isLoading = false
    },
    resetPhoto () {
      this.file = null
    },
    openEdit ( user ) {
      this.$refs.edit.open( user )
    },
    openAssign ( user ) {
      this.$refs.assign.open( user )
    },
    openRemoveDialog ( user ) {
      this.$buefy.dialog.confirm({
        title: `¿Desea eliminar el usuario?`,
        message: `Al confirmar esta acción, se eliminará la empresa " ${user.names} ${user.paternalSurname} "
                   de nuestra plataforma.<p>¿Desea continuar?</p>`,
        confirmText: 'Sí, eliminar',
        cancelText: 'No, cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => await this.deleteUser( user )
      })
    },
    async deleteUser ( user ) {
      this.isLoading = true
      try {
        await this.$api.delete( `/users/${user._id}` )
        this.$buefy.toast.open({
          message: 'El usuario se ha eliminado con éxito',
          type: 'is-success'
        })
        this.getUsers()
      } catch ( error ) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
      this.isLoading = false
    },
    async getCompanies() {
      try {
        const { data } = await this.$api.get( '/companies/all' )
        this.companies = data
      } catch ( error ) {
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data.message,
          type: 'is-danger'
        })
      }
    },
    includesCompanies ( user ) {
      if( !this.company ) return false
      const userCompanies = []
      if( ['hrmanager', 'solicitant'].includes( user.role ) ) {
        return !(user.company.id === this.company)
      } else {
        user.companies.forEach( company => {
          userCompanies.push( company.company.id )
        })
        return !userCompanies.includes( this.company )
      }
    },
    includesEmail( user ){
      if( this.userMail && this.userMail.length > 3 ){
        return user.email.includes( this.userMail )
      }else{
        return true
      }
    },
    hasEditionPermissions( user ){
      return this.$hasPermissionsByRole(['superqueen']) ||
             this.allowedRecruitersManagersEditionRoles.some(role => role.value === user.role)
    },
    onCopy: function () {
      this.$buefy.toast.open({
        message: 'Correo copiado correctamente',
        type: 'is-success'
      })
    },
    onError: function ( e ) {
      this.$buefy.toast.open({
        message: 'Error al copiar el correo electrónico ' + e.text,
        type: 'is-warning'
      })
    }
  },
  async mounted () {
    await this.$store.dispatch("getAuthorVacancies")
    await this.getCompanies()
  },
}
</script>

<style lang="scss">
  .p-sidebar.b-sidebar {
    .sidebar-content {
      width: 50%;
    }
  }
  .iconos{
    color: #42CBB6;
  }
  .iconos:hover{
    color: #2B9E8C;
  }
</style>
