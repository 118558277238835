<template>
  <div class="container pb-50">
    <div class="pt-5 column is-flex justify-flex-end">
      <b-button
        rounded
        icon-left="arrow-left"
        type="is-primary"
        tag="router-link"
        :to="{ name: 'Vacancies', params: { id: companyId } }"
      >
        {{ generalDataTranslation.returnButton }}
      </b-button>
    </div>

    <div class="columns">
      <div class="column">
        <span class="is-size-3">
          <strong>{{ company?.name }}</strong> | {{ generalDataTranslation.newVacancy }}
        </span>
      </div>
    </div>

    <div class="columns">
      <div class="column is-9 mt-3">
        <ValidationObserver ref="observer">
          <div
            class="card column is-12 py-5 px-3"
            style="border-radius: 15px"
          >
              <!-- Vacancy Language Form -->
              <VacancyFormLanguage ref="vacancyFormLanguage"
              />
              <hr />

              <!-- General Data -->
              <GeneralData ref="generalData"
                :renameField="renameField"
                :newPlaceholder="newPlaceholder"
              />
              <hr />

              <!-- Academic Data -->
              <AcademicData ref="academicData"
                :renameField="renameField"
                :newPlaceholder="newPlaceholder"
              />
              <hr />

             <!-- Languages -->
              <LanguageData ref="languageData"
                :renameField="renameField"
                :newPlaceholder="newPlaceholder"
              />
              <hr />

              <!-- Habilities and knowledges -->
              <VacancyHabilities ref="habilities"
                :renameField="renameField"
                :newPlaceholder="newPlaceholder"
              />
              <hr />

              <!-- Description -->
              <VacancyDescription ref="description"
                :renameField="renameField"
                :newPlaceholder="newPlaceholder"
              />
              <hr />

              <!-- Solicitants -->
              <VacancySolicitants ref="solicitants"
                :renameField="renameField"
                :newPlaceholder="newPlaceholder"
                :authors="authors"
                @usersUpdated="getUsers"
              />
              <hr />

              <!-- Recruitment -->
              <VacancyRecruitment ref="recruitment"
                :renameField="renameField"
                :newPlaceholder="newPlaceholder"
              />
              <hr />

              <!-- Project -->
               <VacancyProject
              />
              <hr />

              <!-- KillerQuestions -->
              <VacancyKillerQuestions ref="killerQuestions" 
                :renameField="renameField"
                :newPlaceholder="newPlaceholder"
              />
              <hr />

              <!-- Comments -->
              <VacancyComments ref="comments"
                :renameField="renameField"
                :newPlaceholder="newPlaceholder"
              />
              <hr />

              <!-- Additional fields -->
              <VacancyAdditionalFields ref="otherFields"
              />
          </div>
        </ValidationObserver>
      </div>

      <VacancyMondayId
        :validateForms="validateForms"
        :companyId="auxCompanyId"
      />
    </div>

    <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
  import {
    getDefaultVacancyFields,
  } from "../lib/vacancyDictionaries";

  import {
    getCompanyContacts,
    getRenamedFields,
  } from "../api/vacancy";
  import { mapGetters } from "vuex";

  import VacancyFormLanguage from "../components/vacancy-form/VacancyFormLanguage.vue";
  import GeneralData from "../components/vacancy-form/GeneralData.vue";
  import AcademicData from "../components/vacancy-form/AcademicData.vue";
  import LanguageData from "../components/vacancy-form/LanguageData.vue";
  import VacancyHabilities from "../components/vacancy-form/VacancyHabilities.vue";
  import VacancyDescription from "../components/vacancy-form/VacancyDescription.vue";
  import VacancySolicitants from "../components/vacancy-form/VacancySolicitants.vue";
  import VacancyRecruitment from "../components/vacancy-form/VacancyRecruitment.vue";
  import VacancyProject from "../components/vacancy-form/VacancyProject.vue";
  import VacancyKillerQuestions from "../components/vacancy-form/VacancyKillerQuestions.vue";
  import VacancyComments from "../components/vacancy-form/VacancyComments.vue";
  import VacancyAdditionalFields from "../components/vacancy-form/VacancyAdditionalFields.vue";
  import VacancyMondayId from "../components/vacancy-form/VacancyMondayId.vue";

  export default {
    components: {
      VacancyFormLanguage,
      GeneralData,
      AcademicData,
      LanguageData,
      VacancyHabilities,
      VacancyDescription,
      VacancySolicitants,
      VacancyRecruitment,
      VacancyProject,
      VacancyKillerQuestions,
      VacancyComments,
      VacancyAdditionalFields,
      VacancyMondayId,
    },
    data() {
      return {
        isLoading: false,
        renamedFields: [],
        authors: [],
        auxCompanyId: null,
      };
  },
    watch: {
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      user() {
        return this.$store.state.session.user;
      },
      company() {
        return this.$store.state.company;
      },
      fieldNames () {        
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
      generalDataTranslation() {
        return this.$t("screens.vacancies.edit.generalData");
      },
    },
    async mounted () {
      this.$i18n.locale = process.env.VUE_APP_LOCATION || 'mx' ; // Cambia el idioma actual de i18n
      this.auxCompanyId = this.$route.query.id;
      await this.getCompany();
    },
    methods: {
      async getCompany () {
        try {
          this.isLoading = true
          const response = await this.$api.get(`/companies/${this.$route.query.id}`)
          this.$store.commit('setCompany', response.data)
          
          this.renamedFields = await getRenamedFields(this.$route.query.id);
          this.authors = await getCompanyContacts(this.$route.query.id);
        } catch (error) { 
          this.$buefy.toast.open({
            message: !error.response ? error : error.response.data?.message,
            type: 'is-danger',
            duration: 6000
          })
        }
        this.isLoading = false
      },
      renameField(actualName) {
        const newField = this.renamedFields.find((field) => actualName === field?.actualName);
        return newField?.newName || actualName;
      },
      newPlaceholder(actualName) {
        const newField = this.renamedFields.find((field) => actualName === field?.actualName);
        return newField?.newPlaceholder || "";
      },
      async getUsers() {
        this.authors = await getCompanyContacts(this.companyId);
      },
      async validateForms() { // Validating all forms where correctly filled
        const forms = await Promise.all(
          [
            this.$refs.vacancyFormLanguage.validateForm(),
            this.$refs.generalData.validateForm(),
            this.$refs.academicData.validateForm(),
            this.$refs.languageData.validateForm(),
            this.$refs.habilities.validateForm(),
            this.$refs.description.validateForm(),
            this.$refs.solicitants.validateForm(),
            this.$refs.recruitment.validateForm(),
            this.$refs.killerQuestions.validateForm(),
            this.$refs.comments.validateForm(),
            this.$refs.otherFields.validateForm()
          ]
        )

        // Making main form
        if (forms.every((form) => form)) {
          let form = {}

          forms.forEach(formItem => {
            if(formItem)
              form = {...form, ...formItem}
          });

          return { form };
        } else{
          return false
        }
      },
    },
  };
</script>

<style>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

  .academicOptions--option {
    margin-top: 2px;
  }
  .language-table {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
  }
  /* Removes padding from labels */
  .field-label {
    flex-grow: 0 !important;
  }
</style>
