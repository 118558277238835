import axios from '@/axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: {
      user: {
        id: null,
        role: null,
        email: null,
        company: null
      },
    },
    company: {},
    recruiters: [],
    project: {
      id: null,
      name: null
    },
    userVacancies: [],
    userCompanies: []
  },
  actions: {
    logout({ commit }) {
      commit('logout')
    },
    login({ commit }, session) {
      commit('login', session)
    },
    async getRecruiters({ commit }) {
      const { data } = await axios.get(`/recruiters`)
      commit('setRecruiters', data)
    },
    getProjectName({ commit }, name) {
      commit('setProjectName', name)
    },
    deleteProjectName({ commit }) {
      commit("deleteProjectName");
    },
    async getAuthorVacancies({ commit },data) {

      commit('setUserVacancies', data);
    },
    async getUserCompanies ({ commit }, companiesIds = []) {
      if (!this.state.session.user.id) return
      const response = await axios.get(`/companies?user=${this.state.session.user.id}`)
      const allowedIds = response.data?.allowed || []

      const companies = [...new Set([...companiesIds, ...allowedIds])]

      commit('setUserCompanies', companies)
    }
  },
  mutations: {
    logout(state) {
      state.session = {
        user: {
          id: null,
          role: null,
          email: null,
          company: null
        },
      }
      state.company = {}
      state.recruiters = []
      state.project.name = null
      state.userVacancies = []
      state.userCompanies = []
    },
    login(state, session) {
      state.session = session
    },
    setCompany(state, company) {
      state.company = company
    },
    setRecruiters(state, recruiters) {
      state.recruiters = recruiters
    },
    setProjectName(state, name) {
      state.project.name = name || 'Por definir'
    },
    deleteProjectName(state) {
      state.project.name = null
    },
    setUserVacancies(state, vacancies) {
      state.userVacancies = vacancies
    },
    setUserCompanies(state, companies) {
      state.userCompanies = companies
    }
  },
  getters: {
    companyId(state) {
      return state.company.id || null
    },
    unassignedRecruiters(state) {
      return state.recruiters.filter(recruiter => recruiter.deleted || !recruiter.companyRecruiter)
    },
    projectName(state) {
      return state.project.name || 'Por definir'
    },
    projectId(state) {
      return state.project.id || 'Por definir'
    }
  }
})
