<template>
  <div class="column is-12 m-0 is-sticky">
    <div class="card">
      <div class="card-content">
        <h4 class="title is-size-6 mb-1">Propiedades de sección</h4>

        <div class="is-flex is-flex-direction-column" v-if="defaultMediaSection?._id">
          <div class="column is-12">
            <div class="mb-3">
              <label class="label is-size-6 has-text-weight-medium">Nombre</label>
              <div class="control">
                <input class="input" type="text" v-model="mediaSection.name" @blur="updateMediaSection" />
              </div>
            </div>

            <div class="mb-3" v-if="availableMediaQueries.length > 0">
              <label class="label is-size-6 has-text-weight-medium">Resoluciones</label>
              <div class="control">
                <div class="is-vertical">
                  <div class="column p-0 pl-1" v-for="(query, index) in availableMediaQueries" :key="index">
                    <b-field>
                      <b-checkbox 
                        v-model="mediaSection.mediaQueries" 
                        :native-value="query" 
                        @input="updateMediaSection"
                        :disabled="mediaSection.mediaQueries.length == 1 && query._id == mediaSection.mediaQueries[0]._id"
                      >
                        {{ query.alias }}
                      </b-checkbox>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="mb-3">
              <label class="label is-size-6 has-text-weight-medium">Imagen de fondo</label>
              
              <div class="is-flex is-justify-content-center" v-if="!isLoading">
                <b-upload 
                  v-model="mediaSection.backgroundImageFile" 
                  accept=".webp, .jpg, .jpeg, .png, .gif, .tiff, .ico"
                  @input="uploadLocalImage"
                >
                  <a class="button is-primary">
                    <b-icon icon="upload" />
                  </a>
                </b-upload>
                <span v-if="mediaSection.backgroundImage?.name" class="file-name mr-5">
                  {{ mediaSection.backgroundImage.name }}
                </span>
                <span v-else class="file-name mr-2">
                  Selecciona una imagen de fondo
                </span>
                <b-button 
                  type="is-danger"
                  @click="deleteLocalImage"
                  :disabled="!mediaSection.backgroundImage?.key"
                >
                  <f-icon icon="fa-regular fa-trash-can" />
                </b-button>
              </div>
              <div class="loading-wrapper" v-else>
                <b-loading :active="isLoading" :is-full-page="false" />
              </div>
            </div>
          </div>

          <hr class="separation-line">
          <div class="column is-flex is-justify-content-space-between">
            <div>
              <b-button 
                type="is-info"
                @click="riseSection"
                class="mr-1"
              >
                <f-icon icon="fa-regular fa-arrow-up"/>
              </b-button>
              <b-button 
                type="is-info"
                @click="descendSection"
              >
                <f-icon icon="fa-regular fa-arrow-down"/>
              </b-button>
            </div>

            <b-button 
              type="is-danger"
              @click="openRemoveSectionModal"
            >
              <f-icon icon="fa-regular fa-trash-can" class="mr-2"/>
              Eliminar sección
            </b-button>
          </div>
        </div>
        
        <div class="pr-3 pl-3 pt-5 pb-5 is-flex is-flex-direction-column is-justify-content-center is-align-items-center" v-else>
          <f-icon icon="fa-regular fa-hand-pointer" class="has-text-grey mb-2" />
          <p class="has-text-grey">Selecciona una sección para editar.</p>
        </div>
      </div>
    </div>

    <!-- Delete section confirmation modal -->
    <b-modal
      :active.sync="showRemoveSectionModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <div class="modal-card-head">
          <strong>
            <f-icon icon="fa-regular fa-folder-grid" class="mr-1"/>
            ¿Estás seguro que deseas eliminar la sección?
          </strong>
        </div>
        <div class="modal-card-body">
          Esto provocará que se eliminen <strong>todos sus recursos</strong> que fueron subidos anteriormente.
        </div>
        <div class="modal-card-foot justify-flex-end">
          <b-button
            type="is-danger"
            @click="removeSection"
          >
            Sí, eliminar
          </b-button>
          <b-button
            type="is-grey"
            @click="closeRemoveSectionModal"
          >
            No, mantener.
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    defaultMediaSection: {
      type: Object,
      default: () => ({})
    },
    availableMediaQueries: {
      type: Array,
      default: () => []
    },
    uploadImage: {
      type: Function
    },
    deleteImage: {
      type: Function
    },
    riseSection: {
      type: Function,
      default: () => {}
    },
    descendSection: {
      type: Function,
      default: () => {}
    },
    deleteSections: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      mediaSection: {
        name: '',
        backgroundImage: {
          url: '',
          name: '',
          key: ''
        },
        backgroundImageFile: null,
        mediaQueries: []
      },
      showRemoveSectionModal: false,
      isLoading: false
    }
  },
  mounted() {
    this.mediaSection = {...this.mediaSection, ...this.defaultMediaSection}
  },
  watch: {
    defaultMediaSection: {
      handler(val) {
        if (val) {
          this.mediaSection = {...this.mediaSection, ...val}
        }
      },
      deep: true,
    },
    'mediaSection.name': {
      handler(val) {
        if (val.includes(' ')){
          this.$buefy.toast.open({
            message: 'El nombre de la sección no puede contener espacios',
            type: "is-light",
            duration: 5000
          });
          this.mediaSection.name = val.replace(' ', '-')
        }
      }
    }
  },
  methods: {
    async updateMediaSection() {
      this.$emit('updateMediaSection', this.mediaSection);
    },
    async uploadLocalImage(){
      this.isLoading = true
      const file = this.mediaSection.backgroundImageFile;
      const fileIdentifier = `section-${this.mediaSection._id}`;
      const fileMetadata = this.mediaSection.backgroundImage;

      try {
        this.mediaSection.backgroundImage = await this.uploadImage(file, fileIdentifier, fileMetadata, "archivo para la imagen de fondo");
        await this.updateMediaSection();
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    },
    async deleteLocalImage(){
      this.isLoading = true
      try {
        this.mediaSection.backgroundImage = await this.deleteImage(this.mediaSection.backgroundImage.key);
        await this.updateMediaSection();
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    },
    async removeSection() {
      await this.deleteSections([this.mediaSection._id])
      await this.updateMediaSection()
      this.closeRemoveSectionModal();
    },
    openRemoveSectionModal(){
      this.showRemoveSectionModal = true
    },
    closeRemoveSectionModal(){
      this.showRemoveSectionModal = false
    },
  }
}
</script>

<style scoped>
.loading-wrapper{
  position: relative;
  min-height: 45px;
}
.separation-line{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.9rem;
  margin-right: 0.9rem;
  height: 2px;
}
</style>
