import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import CandidateProfile from '@/screens/CandidateProfile'
import _403 from '@/screens/errors/403'
import _404 from '@/screens/errors/404'
import Maintenance from '@/screens/Maintenance'
import { ToastProgrammatic as Toast } from 'buefy'
import httpApi from '../axios'
import auth from './auth'
import company from './company'
import pdf from './pdf'
import recruitment from './recruitment'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'designer', 'designer-manager', 'manager', 'account-manager', 'account', 'hrmanager', 'solicitant']
    },
    component: {
      render: (r) => r('router-view')
    },
    beforeEnter: (to, from, next) => {
      const role = store.state.session.user.role
      const company = store.state.session.user.company
      const routes = {
        superqueen: '/empresas',
        recruiter: '/empresas',
        'recruiter-manager': '/empresas',
        designer: '/empresas',
        'designer-manager': '/empresas',
        manager: '/empresas',
        hrmanager: `/empresa/${company}/vacantes`,
        solicitant: `/empresa/${company}/vacantes`,
        account: `/empresas`,
        'account-manager': `/empresas`
      }
      if (!role) {
        next('/login')
      }
      next(routes[role])
    }
  },
  ...auth,
  ...company,
  ...recruitment,
  ...pdf,
  {
    path: '/download-candidate-profile',
    component: CandidateProfile,
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'designer', 'designer-manager', 'manager', 'account-manager', 'account']
    },
  },
  {
    path: '/maintenance',
    component: Maintenance,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/403',
    component: _403,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '*',
    component: _404,
    meta: {
      requireAuth: false,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // Comprobando modo mantenimiento
  if (process.env.VUE_APP_MAINTENANCE_MODE === 'true') {
    if (from.path !== '/maintenance' && to.path !== '/maintenance') {
      next('/maintenance')
      return
    }
  }

  // Comprobando que requiera autenticación
  if (to.meta.requireAuth === false) {
    store.dispatch('logout')
    next()
    return
  }

  // Tratando de recuperar sesión 
  let user;
  let permissions = to.meta.permissions;

  try {
    // Si se tiene una cookie de sesión, se intenta recuperar la sesión
    const { data } = await httpApi.get('/auth/get-user-auth')
    user = data.user
    store.dispatch('login', { user }) // Establece state.session

    if (permissions?.length > 0) {
      const hasAuth = permissions.includes(user.role)

      if (!hasAuth) { // Si no tiene permisos para acceder a la página
        Toast.open({
          message: "No tienes permisos para acceder a esta página.",
          type: "is-danger",
          duration: 3000,
        })
        next(from.path)
        return
      }
      next()
      return

    } else { // Si no hay definición de permisos (error de diseño)
      Toast.open({
        message: "Necesitas tener permisos para acceder a esta página",
        type: "is-danger",
        duration: 3000,
      })
      next(from.path)
      return
    }
  } catch (error) { // Error de autenticación en API redirige a login
    Toast.open({
      message: error.response ? error.response?.data?.message : error,
      type: "is-danger",
      duration: 3000,
    })
    store.dispatch('logout')
    next('/login')
    return
  }
})

export default router
