<template>
  <div
    class="hero is-fullheight justify-center"
    style="
      background: #0f486d;
      background: linear-gradient(339deg, #0f486d 0%, rgba(66,203,182,1) 100%);
    "
  >
    <div class="columns is-centered">
      <div class="column is-3">
        <div class="card">
          <div class="card-header">
            <div class="column">
              <div class="is-size-5">
                Bienvenid@ a Pro Meritum
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="pb-20">
              Establece una contraseña para tu cuenta nueva.
              <br>
            </div>
            <ValidationObserver ref="observer" v-slot="{ passed }">
              <ValidationProvider
                vid="password"
                rules="password_complexity"
                name="Contraseña"
                v-slot="{ errors, valid }"
              >
                <b-field
                  label="Contraseña"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors[0]"
                >
                  <b-input
                    type="password"
                    password-reveal
                    v-model="form.password"
                  />
                </b-field>
              </ValidationProvider>
              <br>
              <div class="columns is-multiline">
                <div class="column is-12 has-text-right">
                  <b-button
                    type="is-primary"
                    :disabled="!passed"
                    @click="updatePassword"
                  >
                    Registrarse
                  </b-button>
                </div>
              </div>
            </ValidationObserver>
            <a href="/login">Ya cuento con contraseña</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthInvite',
  data () {
    return {
      form: {
        password: null
      }
    }
  },
  computed: {
    invite () {
      const { invite } = this.$route.query
      return invite
    }
  },
  async beforeMount () {
    await this.$store.dispatch('logout')
    await this.verifyInvite()
  },
  methods: {
    async updatePassword () {
      try{
        const valid = await this.$refs.observer.validate()
        if(valid){
          const { data } = await this.$api.post(`/auth/update-password-by-invite/${this.invite}`, this.form)
          this.$store.dispatch('login', data)
          this.$router.push('/')
        }
      }catch(error){
        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data?.message,
          type: 'is-danger',
          duration: 3000
        })
      }
    },
    async verifyInvite () {
      try {
        const { data } = await this.$api.post(`/auth/verify-invite/${this.invite}`)

        if (data) {
          this.$store.dispatch('login', data)
          this.$router.push('/')
        }

      } catch (error) {
        this.$router.push('/login')

        this.$buefy.toast.open({
          message: !error.response ? error : error.response.data?.message,
          type: 'is-danger',
          duration: 6000
        })
      }
    }
  }
}
</script>

<style>

</style>